<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="10"></ucMenu>
					<div class="right_con">
						<div class="setting_main">
							<p class="fb">修改密码</p>
							<div class="setiting_wrap">
								<div class="setting_line">
									<p class="txt">新密码：</p>
									<p class="con"><el-input class="in" placeholder="请输入新密码" v-model="newPassword"></el-input></p>
								</div>
								<div class="setting_line">
									<p class="txt"></p>
									<p class="con">
										<el-button type="primary" @click="changePwd">确定修改</el-button>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import common from '../../assets/js/utils/common';
export default {
	name: "ucenterSettingPwd",
	data() {
		return {
			newPassword:"",
			bol_submit:false,
		}
	},
	components:{
		myHead,myFoot,ucMenu
	},
	computed:{
		...mapState({
			
		}),
	},
	created(){
	},
	methods: {
		...mapActions({
			opAgentuserResetPwd:"ucenter/opAgentuserResetPwd",
		}),
		...mapMutations({
			OUT_LOGIN:"login/OUT_LOGIN"
		}),
		changePwd(){
			if(!this.newPassword){
				this.$message({type: 'info',message: "请输入新密码！"})
				return false
			}
			if(!this.bol_submit){
				this.bol_submit = true
				this.opAgentuserResetPwd({data:{password:this.newPassword},success:(res)=>{
					this.bol_submit = false
					if(res.status==200){
						console.log('opAgentuserResetPwd',res)
						this.newPassword = ""
						this.$message({message: "修改成功，请重新登录！",type: 'success',});
						this.OUT_LOGIN()
						this.$router.replace('./')
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
				}})
			}
		},
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;background: #fff;}
.setting_main{padding: 36px 40px;}
.setiting_wrap{padding-top: 25px;}
.setting_line{margin-top: 30px;display: flex;font-size: 14px;line-height: 1;align-items: center;}
.setting_line .txt{width: 100px;text-align: right;}
.print_product{display: flex;flex-direction: column;align-items: flex-start;width: 100%;padding-top: 20px;}
.print_product .el-textarea{margin-top: 15px;}
.setting_line .con{flex: 1;width: 0;margin-left: 30px;display: flex;align-items: center;}
.setting_line .con .iconfont{font-size: 16px;color: #666666;margin-left: 10px;cursor: pointer;}
.setting_line .con .in{width: 200px;}
.phone_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.phone_mask.active{visibility: visible;opacity: 1;}
.phone_mask .phone_main{width: 400px;height: 312px;box-sizing: border-box;padding: 36px 0 28px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.phone_mask.active .phone_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.phone_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.phone_main .title{margin: 0 32px;font-size: 20px;line-height: 1;}
.phone_main .input_line{margin: 24px 32px 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.phone_main .input_line .txt{width: 90px;font-size: 12px;color: #999999;}
.phone_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.phone_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.phone_main .tips{margin: 10px 32px 0;line-height: 1;font-size: 12px;color: #666666;}
.phone_main .btn_wrap{margin: 32px 32px 0;display: flex;justify-content: space-between;}
.phone_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.phone_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.phone_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.password_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.password_mask.active{visibility: visible;opacity: 1;}
.password_mask .password_main{width: 400px;height: 312px;box-sizing: border-box;padding: 36px 0 28px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.password_mask.active .password_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.password_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.password_main .title{margin: 0 32px 15px;font-size: 20px;line-height: 1;}
.password_main .input_line{margin: 10px 32px 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.password_main .input_line .txt{width: 76px;font-size: 12px;color: #999999;}
.password_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.password_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.password_main .tips{margin: 10px 32px 0;line-height: 1;font-size: 12px;color: #666666;}
.password_main .btn_wrap{margin: 32px 32px 0;display: flex;justify-content: space-between;}
.password_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.password_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.password_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.btn_log{color: #f0413e;margin-left: 20px;cursor: pointer;}

.chongzhi_main{box-sizing: border-box;background: #fff;}
.chongzhi_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.chongzhi_main .title{display: flex;align-items: center;margin: 0 22px;font-size: 20px;line-height: 1;}
.chongzhi_main .title .icon{width: 22px;height: 22px;margin-right: 8px;}
.chongzhi_main .input_line{margin: 15px 22px 0;display: flex;align-items: center;}
.chongzhi_main .input_line .txt{font-size: 12px;color: #666666;margin-right: 10px;}
.chongzhi_main .input_line .in{width: auto;}
.chongzhi_main .code{width: 155px;height: auto;display: block;margin: 30px auto 0;}
.chongzhi_main .btn_wrap{margin: 45px 0 0;display: flex;justify-content: space-between;}
.chongzhi_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.chongzhi_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.chongzhi_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.chongzhi_main .explain{color: #f0413e;font-size: 14px;font-weight: bold;}

</style>
<style>
.el-cascader{width: 100%;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px !important;height: 34px;line-height: 34px;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}
.totals_btn .el-button--primary.is-plain{border-color: #fff;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-input.is-focus .el-input__inner,.el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}
.rc_table.el-table th.el-table__cell>.cell{padding: 0 14px;}
</style>
